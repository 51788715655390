import React from 'react'
import { Image } from '@/atoms/Image'
import { useTranslate } from '@/utils/translate/translate-client'
import { scaleDimensions } from '../utils'
import { BaseLogoProps } from './LogoTypes'

export const GoogleStoreDownloadColorLogo: React.FC<BaseLogoProps> = ({ height = 24, className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 83, 24)
  const { t } = useTranslate('app-promo')

  return (
    <Image
      width={scaledWidth}
      height={scaledHeight}
      src={`/v1670262802/angel-studios/landing/google-play-badge.webp`}
      className={className}
      alt="Google Play Store Logo"
      aria-label={t('getItOnGooglePlay', 'Get it on Google Play')}
    />
  )
}
