import React from 'react'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const MicrosoftXboxTextLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 47, 18)
  const hex = getHexColor(color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height={scaledHeight}
      width={scaledWidth}
      aria-label="MicrosoftXboxApp"
      viewBox="0 0 62.59 19.022"
    >
      <path
        fill={hex}
        d="M61.091 15.366l-3.645-4.947-3.641 4.947h-1.5l4.382-5.977-4.002-5.433h1.5l3.261 4.431 3.261-4.431h1.499l-3.999 5.432 4.383 5.978zM32.688 15.364l-4.387-5.976 4.002-5.432h-1.5l-3.262 4.432-3.259-4.432h-1.496l4 5.432-4.386 5.976h1.502l3.641-4.945 3.644 4.945z"
      />
      <path
        fill={hex}
        d="M41.028 9.82a3.197 3.197 0 0 0-.748-.476 2.585 2.585 0 0 0 1.372-2.285c0-.73-.269-3.104-3.709-3.104H33.31v4.919h-1.543c-.2.261-.655.888-.828 1.145h2.371v5.345l4.634.001c2.51 0 4.069-1.274 4.069-3.331 0-.898-.339-1.661-.985-2.214m-6.437-4.674h3.353c.907 0 2.427.226 2.427 1.813 0 1.2-.885 1.915-2.427 1.915h-3.353V5.146zm3.352 9.029H34.59v-4.153h3.353c1.271 0 2.792.351 2.792 1.987 0 1.884-1.749 2.166-2.792 2.166M48.04 3.693c-3.205 0-5.445 2.451-5.445 5.961 0 3.515 2.24 5.964 5.445 5.964 3.202 0 5.436-2.449 5.436-5.964 0-3.51-2.234-5.961-5.436-5.961m0 10.718c-2.442 0-4.153-1.957-4.153-4.757s1.711-4.756 4.153-4.756c2.438 0 4.139 1.956 4.139 4.756s-1.701 4.757-4.139 4.757M2.838 2.735l-.009.008v.001A9.48 9.48 0 0 0 0 9.511a9.47 9.47 0 0 0 1.944 5.761c.013.016.033.025.051.016.017-.01.018-.031.011-.049-.725-2.242 2.981-7.676 4.896-9.954.006-.008.014-.017.014-.028 0-.013-.009-.023-.018-.031C3.677 2.02 2.817 2.753 2.829 2.743M16.184 2.735l.009.008v.001a9.478 9.478 0 0 1 2.828 6.767 9.47 9.47 0 0 1-1.944 5.761c-.012.016-.033.025-.051.016-.016-.01-.017-.031-.011-.049.725-2.242-2.978-7.68-4.892-9.959-.007-.008-.014-.017-.014-.027 0-.014.008-.024.018-.032 3.222-3.206 4.076-2.468 4.065-2.478M9.511 0c1.919 0 3.498.526 4.954 1.392.018.009.023.029.016.047-.007.017-.027.021-.046.016-1.841-.398-4.64 1.193-4.904 1.352-.006.004-.012.008-.02.008-.009 0-.015-.004-.021-.008-.702-.403-3.289-1.759-4.918-1.342-.018.004-.038.001-.046-.017a.038.038 0 0 1 .016-.047C5.998.526 7.597 0 9.511 0M9.511 7.628c.011 0 .023.009.03.015 2.846 2.164 7.717 7.514 6.23 9.027l.002-.002-.001.002h-.002a9.471 9.471 0 0 1-6.26 2.353 9.476 9.476 0 0 1-6.261-2.353l-.002-.002.002.002c-1.484-1.513 3.386-6.863 6.229-9.027.008-.006.021-.015.033-.015"
      />
    </svg>
  )
}
