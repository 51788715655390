import React from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { AppleStoreDownloadLogo } from '@/atoms/Logos/AppleStoreDownloadLogo'
import { AppleTvDownloadLogo } from '@/atoms/Logos/AppleTvDownloadLogo'
import { FireTVLogo } from '@/atoms/Logos/FireTVLogo'
import { GoogleStoreDownloadColorLogo } from '@/atoms/Logos/GoogleStoreDownloadColorLogo'
import { GoogleTvLogo } from '@/atoms/Logos/GoogleTvLogo'
import { LgTvLogo } from '@/atoms/Logos/LgTvLogo'
import { MicrosoftXboxTextLogo } from '@/atoms/Logos/MicrosoftXboxTextLogo'
import { RokuLogo } from '@/atoms/Logos/RokuLogo'
import { SamsungTvLogo } from '@/atoms/Logos/SamsungTVLogo'
import { paths } from '@/constants/paths'
import { ScreenType, useScreenType } from '@/utils/ScreenUtils'
import { useSafeTrack } from '@/utils/analytics'

interface AppDownloadLinksProps {
  allowWrap?: boolean
  androidDownload?: string
  iosDownload?: string
  fireTvDownload?: string
  appleTvDownload?: string
  rokuDownload?: string
  googleTvDownload?: string
  samsungTvDownload?: string
  lgTvDownload?: string
  microsoftDownload?: string
  layout?: 'loose' | 'tight'
  darkMode?: boolean
}

export const AppDownloadLinks: React.FC<AppDownloadLinksProps> = ({
  allowWrap,
  androidDownload = paths.external.googlePlayStore,
  iosDownload = paths.external.appleAppStore,
  fireTvDownload = paths.external.fireTv,
  appleTvDownload = paths.external.appleTv,
  rokuDownload = paths.external.roku,
  googleTvDownload = paths.external.googleTv,
  samsungTvDownload = paths.external.samsungTv,
  lgTvDownload = paths.external.lgTv,
  microsoftDownload = paths.external.microsoft,
  layout = 'loose',
  darkMode = false,
}) => {
  const track = useSafeTrack()

  const handleIosClick = () => {
    track('iOS App Download Clicked')
  }

  const handleAndroidClick = () => {
    track('Android App Download Clicked')
  }

  const handleFireTvClick = () => {
    track('Fire TV App Download Clicked')
  }

  const handleAppleTvClick = () => {
    track('Apple TV App Download Clicked')
  }

  const handleRokuClick = () => {
    track('Roku App Download Clicked')
  }

  const handleGoogleTvClick = () => {
    track('Google TV App Download Clicked')
  }

  const handleSamsungTvClick = () => {
    track('Samsung TV App Download Clicked')
  }

  const handleLgTvClick = () => {
    track('LG TV App Download Clicked')
  }

  const handleMicrosoftClick = () => {
    track('Microsoft App Download Clicked')
  }

  const screenType = useScreenType()

  const deviceLogoHeight = screenType === ScreenType.mobile || screenType === ScreenType.mobileplus ? 30 : 40
  const lightDeviceLogoHeight = screenType === ScreenType.mobile || screenType === ScreenType.mobileplus ? 30 : 35
  const tightDeviceLogoHeight = screenType === ScreenType.mobile || screenType === ScreenType.mobileplus ? 30 : 32

  return (
    <>
      <When condition={darkMode}>
        <div
          className={classNames('flex flex-col items-center justify-center gap-4', {
            'gap-y-8 md:gap-x-16': layout === 'loose',
          })}
        >
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-8 xl:gap-x-16': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleIosClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={iosDownload}
            >
              <AppleStoreDownloadLogo
                className="min-w-[84px] shrink-0 "
                height={layout === 'tight' ? tightDeviceLogoHeight - 1 : deviceLogoHeight + 2}
              />
            </a>
            <a
              target="_blank"
              onClick={handleAndroidClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={androidDownload}
            >
              <GoogleStoreDownloadColorLogo
                className="min-w-[84px] shrink-0"
                height={layout === 'tight' ? tightDeviceLogoHeight - 1 : deviceLogoHeight + 2}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              ' gap-y-8 md:gap-x-8 xl:gap-x-16': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={fireTvDownload}
              onClick={handleFireTvClick}
            >
              <FireTVLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight : deviceLogoHeight}
              />
            </a>
            <a
              target="_blank"
              onClick={handleMicrosoftClick}
              className="min-w-[60px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={microsoftDownload}
            >
              <MicrosoftXboxTextLogo
                className="min-w-[60px]"
                height={layout === 'tight' ? tightDeviceLogoHeight - 2 : deviceLogoHeight - 2}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={rokuDownload}
              onClick={handleRokuClick}
            >
              <RokuLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight - 10 : deviceLogoHeight - 10}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-8 xl:gap-x-16': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleSamsungTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={samsungTvDownload}
            >
              <SamsungTvLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight : deviceLogoHeight + 10}
              />
            </a>
            <a
              target="_blank"
              onClick={handleLgTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={lgTvDownload}
            >
              <LgTvLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight : deviceLogoHeight}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-8 xl:gap-x-16': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleGoogleTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={googleTvDownload}
            >
              <GoogleTvLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight : deviceLogoHeight + 20}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={appleTvDownload}
              onClick={handleAppleTvClick}
            >
              <AppleTvDownloadLogo
                className="min-w-[84px]"
                height={layout === 'tight' ? tightDeviceLogoHeight + 4 : deviceLogoHeight + 5}
              />
            </a>
          </div>
        </div>
      </When>
      <When condition={!darkMode}>
        <div
          className={classNames('flex flex-col items-center justify-center gap-4 w-full', {
            'gap-y-8 md:gap-x-16': layout === 'loose',
          })}
        >
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleIosClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={iosDownload}
            >
              <AppleStoreDownloadLogo className="min-w-[84px] shrink-0" height={lightDeviceLogoHeight - 1} />
            </a>
            <a
              target="_blank"
              onClick={handleAndroidClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={androidDownload}
            >
              <GoogleStoreDownloadColorLogo className="min-w-[84px] shrink-0" height={lightDeviceLogoHeight - 1} />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              ' gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={fireTvDownload}
              onClick={handleFireTvClick}
            >
              <FireTVLogo className="min-w-[70px] xl:min-w-[84px]" color={'black'} height={lightDeviceLogoHeight} />
            </a>
            <a
              target="_blank"
              onClick={handleMicrosoftClick}
              className="min-w-[60px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={microsoftDownload}
            >
              <MicrosoftXboxTextLogo
                className="min-w-[60px]"
                color={'core-gray-950'}
                height={lightDeviceLogoHeight - 2}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={rokuDownload}
              onClick={handleRokuClick}
            >
              <RokuLogo className="min-w-[70px] xl:min-w-[84px]" color={'black'} height={lightDeviceLogoHeight - 10} />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleLgTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={lgTvDownload}
            >
              <LgTvLogo className="min-w-[84px]" color={'core-gray-950'} height={lightDeviceLogoHeight} />
            </a>
            <a
              target="_blank"
              onClick={handleSamsungTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={samsungTvDownload}
            >
              <SamsungTvLogo className="min-w-[84px]" color={'black'} height={lightDeviceLogoHeight + 5} />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              onClick={handleGoogleTvClick}
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={googleTvDownload}
            >
              <GoogleTvLogo className="min-w-[84px]" color={'black'} height={lightDeviceLogoHeight + 10} />
            </a>
            <a
              target="_blank"
              onClick={handleAppleTvClick}
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={appleTvDownload}
            >
              <AppleTvDownloadLogo
                className="min-w-[70px] xl:min-w-[84px]"
                color={'black'}
                height={lightDeviceLogoHeight + 5.5}
              />
            </a>
          </div>
        </div>
      </When>
    </>
  )
}
