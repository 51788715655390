import React from 'react'
import { getHexColor, scaleDimensions } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const RokuLogo: React.FC<OneColorLogoProps> = ({ height = 24, color = 'white', className }) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(height, 78, 24)
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={scaledWidth}
      height={scaledHeight}
      viewBox="0 0 79 25"
      fill="none"
      aria-label="Roku"
    >
      <g clipPath="url(#clip0_807_2611)">
        <g mask="url(#mask0_807_2611)">
          <path
            d="M13.0862 8.25214C13.0862 6.04508 11.3011 4.26767 9.11841 4.26767H7.15076V12.2013H9.11841C11.3011 12.2013 13.0862 10.4228 13.0862 8.25214ZM22.0369 24.0684H14.814L9.07941 16.1523H7.15076V24.0513H0.785156V0.320816H9.90429C15.1614 0.320816 19.4524 3.87681 19.4524 8.25214C19.4524 10.9206 17.8074 13.304 15.3038 14.7622L22.0369 24.0684Z"
            fill={hex}
          />
          <path
            d="M29.2169 9.67748C27.5358 9.67748 26.1059 11.9504 26.1059 14.764C26.1059 17.5777 27.5358 19.8535 29.2169 19.8535C30.937 19.8535 32.3646 17.5777 32.3646 14.764C32.3646 11.9504 30.937 9.67748 29.2169 9.67748ZM39.015 14.764C39.015 20.1391 34.6206 24.4434 29.2169 24.4434C23.815 24.4434 19.4531 20.1391 19.4531 14.764C19.4531 9.393 23.815 5.08643 29.2169 5.08643C34.6206 5.08643 39.015 9.393 39.015 14.764Z"
            fill={hex}
          />
          <path
            d="M53.9512 5.48024L46.6183 12.7732V5.45614H40.2539V24.0525H46.6183V16.5091L54.2738 24.0525H62.2845L52.5561 14.3743L60.614 6.36189V17.3978C60.614 21.0619 62.8293 24.4434 68.4078 24.4434C71.0562 24.4434 73.4865 22.9493 74.6676 21.5974L77.5293 24.0525H78.8877V5.48024H72.5233V15.1561V17.5048C71.8066 18.7491 70.808 19.5314 69.2646 19.5314C67.6987 19.5314 66.9802 18.608 66.9802 15.6545V14.8751V5.48024H53.9512Z"
            fill={hex}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_807_2611">
          <rect width="78.1816" height="24.1991" fill={hex} transform="translate(0.746094 0.310547)" />
        </clipPath>
      </defs>
    </svg>
  )
}
